





































































































































































import { Component, Vue } from "vue-property-decorator";
import { AttachmentHostType, FundDto } from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "notifyDetail",
  components: {
    ExportWord,
    AttachmentsView,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundMaintainDetail extends Vue {
  dataId?: number;
  detail: FundDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "基金详情";
  hostType = AttachmentHostType.Fund;
  instructionsImageArray: string[] = [];
  plaqueImageArray: string[] = [];
  loading = true;

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取详情
  async fetchDetail() {
    this.loading = true;
    api.fund.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
      if (this.detail && this.detail.instructionsImage) {
        this.instructionsImageArray = this.detail.instructionsImage.split(",");
      }
      if (this.detail && this.detail.plaqueImage) {
        this.plaqueImageArray = this.detail.plaqueImage.split(",");
      }
      this.loading = false;
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
